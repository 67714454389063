@import 'sass/styles';

.closeButton {
  position: absolute;
  top: 10px;
  right: 0;
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;
  }
}
