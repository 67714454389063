@import 'sass/styles';

.modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform, opacity;
  z-index: 1000;
  [role='dialog'] {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 100vh;
  }
  &Background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(33, 33, 33, 0.5);
    z-index: 1000;
  }
  &Content {
    align-items: center;
    z-index: 1001;
    background: $white;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 4px;

    > aside {
      position: relative;
    }

    @media (max-width: $maxMobileWidth) {
      &FullTakeover {
        margin: 0;
        width: 100%;
        height: 100vh;
        border-radius: 0;
      }
    }
    @media (max-width: $maxXSmallMobileWidth) {
      margin: 0;
      height: 100vh;
      border-radius: 0;
    }
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;

  &:focus-visible {
    border: 1px solid blue;
  }

  svg {
    width: 12px;
    height: 12px;

    > path {
      stroke: $gunmetal;
      stroke-width: 1.5px;
    }
  }
}
