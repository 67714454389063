@import 'sass/styles';

.skipToContent {
  position: absolute;
  padding: 0 10px;
  height: 26px;
  z-index: 1000;
  background: $white;
  font-size: 14px;
  left: 0;
  min-height: 26px;
  line-height: 26px;
  transform: translateX(-10000%);
  margin: 5px 0px 0px 10px;

  &:focus,
  &:focus-within {
    transform: translateX(0%);
  }
}
