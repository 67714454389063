.modal {
  &Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  &Content {
    position: fixed;
    background: white;
    padding: 64px;
    border-radius: 8px;
    position: relative;
    max-width: 80%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    /* WebKit scrollbar styles */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */

    &::-webkit-scrollbar,
    &::-webkit-scrollbar:hover {
      width: 4px; /* Width of the scrollbar */
    }
  }
  &Close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    cursor: pointer;

    &Icon {
      width: 16;
      height: 16;
    }
  }
}
