.modalContent {
  width: 80vw;

  @media (max-width: 999px) {
    max-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;
  }
}
