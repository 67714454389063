@import 'sass/styles';

.couponCode {
  &Wrapper {
    @include flexAlign(center, center);

    &:before {
      display: flex;
      content: '';
      width: 1px;
      height: 30px;
      background: $white;
      margin: 0 24px;
    }

    @media (max-width: $maxMobileWidth) {
      border: 1px solid $gunmetal;

      &:before {
        display: none;
      }
    }
  }

  &Container {
    @include flexAlign(center, center);
    gap: 16px;
    cursor: pointer;
    width: 100%;

    @media (min-width: $minDesktopWidth) and (max-width: 1170px) {
      flex-direction: column;
      gap: 2px;
    }

    @media (max-width: $maxMobileWidth) {
      flex-direction: column;
      gap: 4px;
      height: 66px;
      padding: 8px 16px;
    }

    &Applied {
      @media (max-width: $maxMobileWidth) {
        display: none;
      }
    }
  }

  &Title {
    @include typographySmall;

    @media (max-width: $maxMobileWidth) {
      font-size: 1rem; /* 16px */
      line-height: 1.5rem; /* 24px */
    }
  }

  &Text {
    @include typographyCtaSmall;

    @media (max-width: $maxMobileWidth) {
      font-size: 0.75rem; /* 12px */
      line-height: 1.125rem; /* 18px */
      letter-spacing: 0.5px;
    }
  }

  &Code {
    display: flex;
    @include typographyCtaMedium;

    @media (max-width: $maxMobileWidth) {
      font-size: 0.875rem; /* 14px */
      line-height: 1.375rem; /* 22px */
      letter-spacing: 0.5px;
    }
  }

  &ApplyBox {
    @include flexAlign(center, center);
    gap: 8px;
  }

  &AppliedBox {
    width: 100%;
    @include flexAlign(center, center);
    gap: 8px;
    height: 66px;
    padding: 8px 16px;
    border: 1px solid $gunmetal;

    > p {
      @include typographyCtaSmall;
    }

    @media (min-width: $minDesktopWidth) {
      display: none;
    }
  }
}

.promoCampaign {
  &Tagline {
    @include flexAlign(center, center);

    @media (max-width: $maxMobileWidth) {
      width: 100%;
      padding: 8px 16px;
      border-top: 1px solid $gunmetal;
      border-right: 1px solid $gunmetal;
      border-left: 1px solid $gunmetal;

      > p {
        color: $red;
      }
    }

    > p {
      display: flex;
      flex: 0 1 auto;
      gap: 4px;
      @include typographyH6;
    }
  }
}
