@import 'sass/styles';

.authModal {
  .content {
    margin: 0;
  }
  .closeIcon {
    width: 11px;
    height: 11px;
    display: flex;
    justify-self: flex-end;
    float: right;
    cursor: pointer;
    margin: 17px 17px 0;
    path {
      fill: $gunmetal;
      stroke-width: 2;
    }
  }
}
.orText {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  align-self: center;
}
.enterEmailText {
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}
.loginForm {
  .message {
    text-align: center;
    margin: 0 auto 15px;
  }
}
.registerForm {
  width: 100%;
  max-width: 475px;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  margin-top: 48px;
  padding: 0 24px 80px 24px;
  .newsletter {
    margin-top: 8px;
    > label {
      margin: 0;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: flex-start;
      span:first-of-type {
        border: 1px solid $gunmetal;
        &:focus-visible {
          border: 2px solid blue;
        }
        > span {
          width: 4px;
          height: 7px;
          border: solid $gunmetal;
          border-width: 0 1px 1px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      > span {
        text-align: left;
        &[aria-hidden='true'] {
          @media (min-width: $minMobileWidth) and (max-width: $minDesktopWidth) {
            margin-top: 0;
          }
          margin-top: -16px;
          max-width: initial;
          line-height: initial;
          display: flex;
          text-align: initial;
        }
      }
    }
  }
  @media (max-width: $maxMobileWidth) {
    height: 85vh;
    width: 100%;
    padding: 0 17px;
    max-width: none;
    max-height: none;
    padding: 0px 24px 80px 24px;
    right: 0;
  }
}
.resetPasswordForm {
  .linkSentText {
    margin-bottom: 32px;
    text-align: center;
    > strong {
      display: inline;
      font-weight: bold;
      word-break: break-word;
    }
  }
  .doneButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    > button {
      width: 100%;
    }
  }
}
.passwordCreation {
  .passwordSensitiveText {
    margin-top: 8px;
    &RulesNotEmpty {
      margin-bottom: 16px;
    }
  }
  .passwordRules {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
    width: 100%;
    padding: 0;
    &Empty {
      display: none;
    }
    .passwordRule {
      min-width: 50%;
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
      > span {
        margin-left: 4px;
      }
      &InValid {
        > svg {
          margin: 0;
          padding: 0;
          path {
            fill: $red;
          }
        }
        > span {
          color: $mediumGray;
        }
      }
    }
  }
}
.passwordInput {
  position: relative;
  .row {
    position: absolute;
    top: 18px;
    right: 20px;
    cursor: pointer;
  }
}
.loginRecaptcha {
  margin-top: 8px;
  > p {
    text-align: left;
    > a {
      color: $gunmetal;
    }
  }
}
.loginForm,
.resetPasswordForm {
  width: 100%;
  max-width: 430px;
  padding: 0px 24px 80px 24px;
  margin-top: 48px;
  @media (max-width: $minMobileWidth) {
    max-width: 350px;
  }
}
.loginForm,
.resetPasswordForm,
.registerForm {
  position: relative;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .title {
    margin-bottom: 32px;
    text-align: center;
  }
  .resetPassword {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $gunmetal;
    cursor: pointer;

    &:focus-visible {
      border: 1px solid blue;
    }
  }
  .buttonError {
    background: $lightGray;
    pointer-events: none;
  }
  .error {
    width: 100%;
    color: $red;
    margin-top: 8px;
    text-align: center;
  }
  .terms {
    margin-top: 8px;
    > a {
      display: inline-block;
      border: none;
      text-decoration: underline;
      padding: 0;
      margin: 0;
      height: auto;
      width: auto;
      color: $gunmetal;
    }
  }
  .link {
    border: none;
    height: 20px;
    margin: 0;
    margin-bottom: 34px;
    text-decoration: underline;
    background-color: transparent;
    width: auto;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }
  .callout {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin: 0 auto;
    margin-top: 32px;
    &Button {
      border: none;
      height: 20px;
      margin: 0;
      margin-left: 8px;
      text-decoration: underline;
      text-decoration-color: $gunmetal;
      background-color: transparent;
      width: auto;
      padding: 0;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:focus-visible {
        border: 1px solid blue;
      }
      > p {
        color: $gunmetal;
        text-decoration-color: $gunmetal;
      }
    }
  }
  .faceBookLogin {
    background: $white;
    border-radius: 4px;
    border: 1px solid $faceBookBlue;
    color: $faceBookBlue;
    justify-content: center;
    margin-bottom: 5px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > span {
      text-align: center;
    }
    > svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      path {
        fill: $faceBookBlue;
      }
    }
  }
}

.createPasswordBox {
  //background: $cream;
  .title {
    margin-bottom: 0;
    text-align: center;
  }
  .noTokenTitle {
    margin: 0;
    padding-top: 64px;
  }
  .emailText {
    margin: 24px 0;
    text-align: center;
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0;
    > button {
      width: 90%;
    }
  }
  @media (max-width: $maxMobileWidth) {
    padding: 20px 16px;
    height: 100vh;
  }
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
