@import 'sass/styles';

.fieldsetBox {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;

  &Container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Inline {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
    }
  }

  &ContainerInlineTextarea {
    align-items: flex-start;
  }

  > div textarea,
  > div input {
    background: rgb(243, 243, 243);
    padding: 10px;
    border: 1px solid rgb(238, 238, 238);
    width: 100%;
  }
}

.formContainer {
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: start;
    color: $gunmetal;
  }
}

.collectionInfoDiv {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 999px) {
    flex-direction: column;
  }

  &NonAdmin {
    flex-direction: column;
  }
}

.flexHalfColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.collectionOptionsDiv {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.checkboxContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(60px, 1fr));
  row-gap: 16px;
  column-gap: 16px;
  justify-content: space-between;

  &Inline {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media (max-width: 999px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
}

.aaForm {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-top: 32px;

  &Inline {
    margin-top: 8px;
  }

  @media (max-width: 999px) {
    flex-direction: column-reverse;
  }
}

.cancelButton {
  @media (max-width: 999px) {
    align-self: center;
  }
}

.radioContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.titleInputBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &Inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }
}

.visibilityPrints {
  display: flex;

  @media (max-width: 999px) {
    flex-direction: column;
    gap: 32px;
  }
}

.makeCollectionBoxInline {
  margin-top: 16px;
}

.flex1 {
  flex: 1;
}

.labelTextarea {
  padding-top: 8px;
}

.inputErrorBox {
  flex: 1;
  display: flex;
  flex-direction: column;

  &Inline {
    flex: 2;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 0;
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;
  }
}
