@import 'sass/styles';

.preferencesModal {
  .closeIcon {
    width: 11px;
    height: 11px;
    display: flex;
    justify-self: flex-end;
    float: right;
    cursor: pointer;
    margin: 17px 17px 0;
    path {
      fill: $gunmetal;
      stroke-width: 2;
    }
  }
  .title {
    margin-top: 24px;
    margin-bottom: 40px;
    text-align: center;
    white-space: nowrap;
  }
  .errorTitle {
    margin-top: -15px;
    margin-bottom: 20px;
    text-align: center;
    color: $red;
  }
  form {
    position: relative;
    width: 100%;
    max-width: 430px;
    padding: 0px 24px 80px 24px;
    margin-top: 32px;
    .loader {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
    }
    .preferencesInput {
      &Box {
        margin-bottom: 24px;
        height: 52px;
        padding: 0 15px;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid $lightGray;
        position: relative;
        display: flex;
        flex-direction: column;
      }
      &Label {
        color: $mediumGray;
        display: flex;
        flex-direction: column;
        width: 100%;
        @include typographyXSmall;
      }
      &Field {
        display: flex;
        flex-direction: row;
        width: 100%;
        > input {
          @include typographyMedium;
          background: transparent;
          border: transparent;
          padding: 0;
          width: 100%;
          &[disabled] {
            color: $gunmetal;
            -webkit-text-fill-color: $gunmetal;
            opacity: 1;
          }
        }
        justify-content: space-between;
        > svg {
          width: 20px;
          height: 12px;
          path {
            stroke-width: 2;
          }
        }
      }
    }
    @media (max-width: $minMobileWidth) {
      max-width: 350px;
    }
  }
}
